import './Table.css';
import { ContractName,  ContractTypeMap } from '../../config/contracts/types';
import { useState } from 'react';
import { TBlockchain } from '../../Blockchains';
import { getBaseContractSet } from '../../config/contracts/contractSet';

type TChannelMeta = { enabledTo: boolean; enabledFrom: boolean; selfReference: boolean }

function getClassName(result: TChannelMeta) {
    if (result.selfReference) {
        if (result.enabledFrom || result.enabledTo) return 'table-danger'
        return 'table-success'
    }

    if (result.enabledTo && result.enabledFrom )
        return 'table-success'

    return 'table-warning'
}

export function Channel({left, right}: {left: TBlockchain, right: TBlockchain}) {
    const [channelMeta, setChannelMeta] = useState<TChannelMeta | { error: Error } | undefined>();

    if (undefined === channelMeta) {
        const leftGate: ContractTypeMap[ContractName.DeBridgeGate] = getBaseContractSet(left).get(ContractName.DeBridgeGate)!.contract

        Promise.all([
            leftGate.methods.getChainToConfig(right.internalId).call(),
            leftGate.methods.getChainFromConfig(right.internalId).call(),
        ])
            .then(([to, from]) => {
                setChannelMeta({
                    enabledTo: to.isSupported,
                    enabledFrom: from.isSupported,
                    selfReference: left.internalId === right.internalId
                })
            }, (err) => {
                console.error(err)
                setChannelMeta({ error :err })
            })

        return <td>👀</td>;
    }

    if ("error" in channelMeta) {
        return (<td>
            😨
            <div><small>{String(channelMeta.error)}</small></div>
        </td>)
    }

    return <td className={getClassName(channelMeta)}>
    { channelMeta.enabledFrom ? "←" : "⨯" }
    { channelMeta.enabledTo ? "→" : "⨯" }
    </td>
}