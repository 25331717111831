import { ContractName, TBaseContractsConfig } from "./contracts/types";

export type TBaseBlockchain = {
    name: string;
    chainId: number;
    subscriptionId?: number;
    rpc: string;
    explorerBaseUrl: string;
    baseContracts: TBaseContractsConfig
}

const baseContracts: TBaseContractsConfig = {
    [ContractName.DeBridgeGate]: '0x43dE2d77BF8027e25dBD179B491e8d64f38398aA',
    [ContractName.DlnSource]: '0xeF4fB24aD0916217251F553c0596F8Edc630EB66',
    [ContractName.DlnDestination]: '0xE7351Fd770A37282b91D153Ee690B63579D6dd7f',
    [ContractName.DeBridgeRouter]: '0x663DC15D3C1aC63ff12E45Ab68FeA3F0a883C251',
    [ContractName.DownscaledTokenFactory]: '0x40ce8e3296fe1ad7020e89606eb8b054b16eec19',
}

export const BlockchainsConfig: TBaseBlockchain[] = [
    {
        name: 'Ethereum',
        chainId: 1,
        rpc: 'https://mainnet.gateway.tenderly.co',
        explorerBaseUrl: 'https://etherscan.io/%s',
        baseContracts
    },
    {
        name: 'Optimism',
        chainId: 10,
        rpc: 'https://mainnet.optimism.io/',
        explorerBaseUrl: 'https://optimistic.etherscan.io/%s',
        baseContracts
    },
    {
        name: 'BNB',
        chainId: 56,
        rpc: 'https://bsc-dataseed.binance.org',
        explorerBaseUrl: 'https://bscscan.com/%s',
        baseContracts
    },
    {
        name: 'Polygon',
        chainId: 137,
        rpc: 'https://polygon-rpc.com/',
        explorerBaseUrl: 'https://polygonscan.com/%s',
        baseContracts
    },
    {
        name: 'Fantom',
        chainId: 250,
        rpc: 'https://rpc.ftm.tools',
        explorerBaseUrl: 'https://ftmscan.com/%s',
        baseContracts
    },
    {
        name: 'Base',
        chainId: 8453,
        rpc: 'https://mainnet.base.org',
        explorerBaseUrl: 'https://basescan.org/%s',
        baseContracts: {
            ...baseContracts,
            [ContractName.DeBridgeGate]: '0xc1656B63D9EEBa6d114f6bE19565177893e5bCBF',
        }
    },
    {
        name: 'Arbitrum',
        chainId: 42161,
        rpc: 'https://arb1.arbitrum.io/rpc',
        explorerBaseUrl: 'https://arbiscan.io/%s',
        baseContracts
    },
    {
        name: 'Avalanche',
        chainId: 43114,
        rpc: 'https://api.avax.network/ext/bc/C/rpc',
        explorerBaseUrl: 'https://snowscan.xyz/%s',
        baseContracts
    },
    {
        name: 'Linea',
        chainId: 59144,
        rpc: 'https://rpc.linea.build/',
        explorerBaseUrl: 'https://lineascan.build/%s',
        baseContracts
    },
    {
        name: 'Neon',
        chainId: 245022934,
        subscriptionId: 100000001,
        rpc: 'https://neon-mainnet.everstake.one',
        explorerBaseUrl: 'https://neonscan.org/%s',
        baseContracts
    },
    {
        name: 'Gnosis',
        chainId: 100,
        subscriptionId: 100000002,
        rpc: 'https://rpc.eu-central-2.gateway.fm/v4/gnosis/non-archival/mainnet',
        explorerBaseUrl: 'https://gnosisscan.io/%s',
        baseContracts
    },
    {
        name: 'LightLink',
        chainId: 1890,
        subscriptionId: 100000003,
        rpc: 'https://replicator.phoenix.lightlink.io/rpc/v1',
        explorerBaseUrl: 'https://phoenix.lightlink.io%s',
        baseContracts
    },
    {
        name: 'Metis',
        chainId: 1088,
        subscriptionId: 100000004,
        rpc: 'https://metis-mainnet.public.blastapi.io',
        explorerBaseUrl: 'https://explorer.metis.io/%s',
        baseContracts
    },
    {
        name: 'Bitrock',
        chainId: 7171,
        subscriptionId: 100000005,
        rpc: 'https://connect.bit-rock.io',
        explorerBaseUrl: 'https://explorer.bit-rock.io/%s',
        baseContracts
    },
    {
        name: 'CrossFi',
        chainId: 4158,
        subscriptionId: 100000006,
        rpc: 'https://rpc.mainnet.ms',
        explorerBaseUrl: 'https://xfiscan.com/%s',
        baseContracts
    },
    {
        name: 'Zilliqa',
        chainId: 388,
        subscriptionId: 100000008,
        rpc: 'https://api.zilliqa.com',
        explorerBaseUrl: 'https://zilscan.io/%s',
        baseContracts
    },
    {
        name: 'zkCronos',
        chainId: 7171,
        subscriptionId: 100000010,
        rpc: 'https://mainnet.zkevm.cronos.org',
        explorerBaseUrl: 'https://explorer.zkevm.cronos.org/%s',
        baseContracts: {
            [ContractName.DeBridgeGate]: '0xa706DaF168865b0b334ef8Ca2418F5AAC55a4b16',
            [ContractName.DlnSource]: '0xA13771CAbd2e44dcA8DeA846cc954D1FbAc0623b',
            [ContractName.DlnDestination]: '0x422C63dc43E8e98a73c608138fCB69C3eCe1eE47',
            [ContractName.DeBridgeRouter]: '0xE59cD1f9867B051fa7e6Ba692521EfbF8C301387',
            [ContractName.DownscaledTokenFactory]: '0xF96ed4A3ad12Cbd3893B096d63910b9eE25C54be',
        }
    },
    {
        name: 'O Chain',
        chainId: 84841,
        subscriptionId: 100000012,
        rpc: 'https://rpc.o.xyz',
        explorerBaseUrl: 'https://explorer.o.xyz/%s',
        baseContracts,
    },
    {
        name: 'Sonic',
        chainId: 7171,
        subscriptionId: 100000014,
        rpc: 'https://rpc.soniclabs.com',
        explorerBaseUrl: 'https://sonicscan.org//%s',
        baseContracts
    },
];