import { ChangeEvent, useState } from "react";
import { CommonLayout } from "../../CommonLayout";
import { CheckRoutersTable } from "./CheckRoutersTable";

export function CheckRouters() {
    const [addresses, setAddresses] = useState<string[]>([]);
    const [tableComponent, setTableComponent] = useState<JSX.Element>();

    const onTextUpdate = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const v = e.target.value.trim()
        const addrToSet = v.split(/[,\n]/g)
            .map(potentialAddress => {
                const value = potentialAddress.trim();
                if (value.match(/^0x[a-z0-9]{40}$/i)) return value;
            })
            .filter(v => !!v) as string[];

        setAddresses(Array.from(new Set(addrToSet)))
    }

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setTableComponent(<CheckRoutersTable key={Date.now()} addresses={addresses}/>)
        event.preventDefault()
    }

    const DisableClick = addresses.length === 0;

    return (
        <CommonLayout>
             <div>
                <div className="col col-sm-5 border p-3">

                    <form>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label">Router addresses</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows={3} onChange={(e) => onTextUpdate(e)}></textarea>
                            {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                        </div>
                        <button type="submit" disabled={DisableClick} className="btn btn-primary" onClick={(event) => onClick(event)}>Check</button>
                    </form>

                </div>
            </div>

            {tableComponent || <></>}

        </CommonLayout>
    )
}