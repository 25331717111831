import Web3 from "web3";
import { getProxyAdminMetadata, TProxyAdminMetadata } from "./ProxyAdmin";
import { getTransparentUpgradableProxyMetadata, TTransparentUpgradableProxyMetadata } from "./TransparentUpgradableProxy";
import { Contract } from "../../config/contracts/contractSet";
import { ContractName } from "../../config/contracts/types";

export type TContractMetadata = {
    version: string;
    contract: TTransparentUpgradableProxyMetadata,
    proxyAdmin: TProxyAdminMetadata,
    proxyAdminOwnerIsAdmin: boolean | undefined
}

export async function getContractMetadata(contract: Contract<ContractName>): Promise<TContractMetadata> {
    const web3 = new Web3(contract.contract.provider)
    const code = await web3.eth.getCode(contract.address);
    if (code === '0x') throw new Error(`Not a contract`)

    const proxyAdmin = await getProxyAdminMetadata(web3, contract.address)
    const proxyAdminOwnerIsAdmin = await contract.contract.methods.hasRole?.('0x0000000000000000000000000000000000000000000000000000000000000000', proxyAdmin.owner).call()
    return {
        version: String(await contract.contract.methods.version().call()),
        contract: await getTransparentUpgradableProxyMetadata(new Web3(contract.contract.provider), contract.address),
        proxyAdmin,
        proxyAdminOwnerIsAdmin
    }
}