import './Table.css';
import { ContractName, ContractTypeMap } from '../../config/contracts/types';
import { useState } from 'react';
import { TBlockchain } from '../../Blockchains';
import { getBaseContractSet } from '../../config/contracts/contractSet';

type TChannelMeta = { enabledTo: boolean; enabledFrom: boolean; selfReference: boolean }

function getClassName(result: TChannelMeta) {
    if (result.selfReference) {
        if (result.enabledFrom || result.enabledTo) return 'table-danger'
        return 'table-success'
    }

    if (result.enabledTo && result.enabledFrom )
        return 'table-success'

    return 'table-warning'
}

export function Channel({left, right}: {left: TBlockchain, right: TBlockchain}) {
    const [channelMeta, setChannelMeta] = useState<TChannelMeta | { error: Error } | undefined>();

    const rightDlnSrcAddress = getBaseContractSet(right).get(ContractName.DlnSource)?.address
    const rightDlnDestinationAddress = getBaseContractSet(right).get(ContractName.DlnDestination)?.address

    if (undefined === channelMeta) {
        const leftDlnSource: ContractTypeMap[ContractName.DlnSource] = getBaseContractSet(left).get(ContractName.DlnSource)!.contract
        const leftDlnDestination: ContractTypeMap[ContractName.DlnDestination] = getBaseContractSet(left).get(ContractName.DlnDestination)!.contract


        Promise.all([
            leftDlnSource.methods.dlnDestinationAddresses(right.internalId).call(),
            leftDlnDestination.methods.dlnSourceAddresses(right.internalId).call(),
        ])
            .then(([whitelistedRightDlnDestinationAddress, whitelistedRightDlnSourceAddress]) => {
                setChannelMeta({
                    enabledFrom: rightDlnDestinationAddress?.toLowerCase() === whitelistedRightDlnDestinationAddress.toString().toLowerCase(),
                    enabledTo: rightDlnSrcAddress?.toLowerCase() === whitelistedRightDlnSourceAddress.toString().toLowerCase(),
                    selfReference: left.internalId === right.internalId
                })
            }, (err) => {
                console.error(err)
                setChannelMeta({ error :err })
            })

        return <td>👀</td>;
    }

    if ("error" in channelMeta) {
        return (<td>
            😨
            <div><small>{String(channelMeta.error)}</small></div>
        </td>)
    }

    return <td className={getClassName(channelMeta)}>
        { channelMeta.enabledFrom ? "←" : "⨯" }
        { channelMeta.enabledTo ? "→" : "⨯" }
        {/* <a href={right.explorer.addr(rightDlnDestinationAddress!)}>{ channelMeta.enabledFrom ? "←" : "⨯" }</a>
        <a href={right.explorer.addr(rightDlnSrcAddress!)}>{ channelMeta.enabledTo ? "→" : "⨯" }</a> */}
    </td>
}