import { Contract } from "web3";
import { DeBridgeRouterAbi } from "../../utils/Contracts/abis/DeBridgeRouter.abi";
import { IDebridgeGateAbi } from "../../utils/Contracts/abis/IDeBridgeGate.abi";
import { DlnDestinationAbi } from "../../utils/Contracts/abis/DlnDestination.abi";
import { CallProxyAbi } from "../../utils/Contracts/abis/CallProxy.abi";
import { DlnSourceAbi } from "../../utils/Contracts/abis/DlnSource.abi";
import { ExternalCallAdapterAbi } from "../../utils/Contracts/abis/ExternalCallAdapter.abi";
import { DownscaledTokenFactoryAbi } from "../../utils/Contracts/abis/DownscaledTokenFactory.abi";

export enum ContractName {
    DeBridgeGate,
    CallProxy,
    DlnSource,
    DlnDestination,
    ExternalCallAdapter,
    DeBridgeRouter,
    DownscaledTokenFactory
    // DeTokenDeployer
}

export type TBaseContractName = ContractName.DeBridgeGate | ContractName.DlnSource | ContractName.DlnDestination | ContractName.DeBridgeRouter | ContractName.DownscaledTokenFactory

export type TBaseContractsConfig = {
    [key in TBaseContractName]: string
}

// Define types for each contract
export interface ContractTypeMap {
    [ContractName.DeBridgeGate]: Contract<typeof IDebridgeGateAbi>;
    [ContractName.CallProxy]: Contract<typeof CallProxyAbi>;
    [ContractName.DlnSource]: Contract<typeof DlnSourceAbi>;
    [ContractName.DlnDestination]: Contract<typeof DlnDestinationAbi>;
    [ContractName.ExternalCallAdapter]: Contract<typeof ExternalCallAdapterAbi>;
    [ContractName.DeBridgeRouter]: Contract<typeof DeBridgeRouterAbi>;
    [ContractName.DownscaledTokenFactory]: Contract<typeof DownscaledTokenFactoryAbi>;
}

export const contractNames: Array<keyof typeof ContractName> = <any>Object
    .values(ContractName)
    .filter((value) => typeof value !== "number")

