import { Navigation } from "./Navigation";

export function CommonLayout({children}: ({ children: JSX.Element | JSX.Element[] | string })) {
    return (
    <div>
      <div className="px-3 pt-2" style={{
        borderBottom: '1px solid white'
      }}>
          <Navigation/>
      </div>
      <div className='table-responsive p-3'>
            {children}
      </div>
    </div>
    )
  }